import axios from './axios.service';

export default class Financing {
  /**
   *
   * @param filters
   */
  static listApplications(filters: any) {
    return axios.get(`/api/v2/cmt-backend/financing/applications`, {
      params: filters,
    });
  }

  /**
   *
   * @param filters
   */
  static exportApplications(filters: any) {
    return axios.get(`/api/v2/cmt-backend/financing/export-applications`, {
      params: filters,
    });
  }

  /**
   *
   * @param partnerId: partnerId to assign
   * @param usersIds: list of users ids to modify
   * @param activationType: activation method
   */
  static assignPartnerToUsers(
    partnerId: any,
    usersIds: any,
    activationType: any,
    financingBalanceAmount: any,
  ) {
    return axios.post(
      '/api/v2/cmt-backend/financing/partner',
      {
        partnerId: partnerId,
        usersIds: usersIds,
        activationType: activationType,
        financingBalanceAmount: financingBalanceAmount,
      },
      {
        headers: {
          Accept: 'application/json',
        },
      },
    );
  }

  static unblockUsers(usersIds: string[], unblockReason: number) {
    return axios.post(
      '/api/v2/cmt-backend/financing/unblock-users',
      {
        usersIds: usersIds,
      },
      {
        headers: {
          Accept: 'application/json',
        },
      },
    );
  }

  /**
   *
   * @param filters
   */
  static listPartners(filters?: any) {
    return axios.get(`/api/v2/cmt-backend/financing/partners`, {
      params: filters,
    });
  }

  static getActivationProcessMethods() {
    return axios.get(`/api/v2/cmt-backend/financing/partners`);
  }

  static getCreditLimit(phoneNumber: any, nationalIDNumber: any) {
    return axios.post(
      `/api/v2/cmt-backend/financing/users/${phoneNumber}/credit`,
      {
        IDCardNumber: nationalIDNumber,
      },
    );
  }

  static registerVerificationAccount(
    phoneNumber: string,
    nationalIDNumber: string,
    reviewedBy: string,
  ) {
    return axios.post(
      `/api/v2/cmt-backend/financing/users/${phoneNumber}/verify-signature`,
      {
        IDCardNumber: nationalIDNumber,
        reviewedBy: reviewedBy,
      },
    );
  }

  static repaymentsConfirmProcessing(uuid: string) {
    return axios.post(
      `/api/v2/cmt-backend/financing/repayments/process-batch/${uuid}`,
      {},
    );
  }
}
